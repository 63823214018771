.app {
  min-height: 100%;
  position: relative;
  font-family: 'Crimson Text', serif;
}

body {
  overscroll-behavior: none;
}

/* /////////////////////////////////////////// Header ////////////////////////////////////////// */

.header {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  background-color: #282c34;
  font-size: 25px;
  padding-top: 1vh;
  font-family: 'Crimson Text', serif;
  border-bottom: 2px solid black;
}

@media only screen and (max-width: 800px) {
  .header {
    font-size: 5vw;
  }
}

.header-name {
  color: wheat
}

.header a {
  color: white;
  padding: 0 2vw;
}

.header a:hover {
  color: orange;
  text-decoration: none;
}

/* /////////////////////////////////////////// Home ////////////////////////////////////////// */

.homepage {
  background-color: #282c34;
  width: 100%;
}

.homepage-name {
  color: white;
  padding-top: 3vh;
  padding-bottom: 2vh;

}

.homepage-name h1 {
  font-size: 40px;
  font-family: 'Crimson Text', serif;
  text-align: center;
}

.homepage-name h4 {
  font-size: 30px;
  font-family: 'Crimson Text', serif;
  text-align: center
}

@media only screen and (max-width: 800px) {
  .homepage-name h1 {
    font-size: 9vw;
  }
  .homepage-name h4 {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 1100px) {
  .frontend, .backend {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

.carousel-container {
  display: flex;
  justify-content: center;
  padding-bottom: 5vh;
}

#carousel {
  box-shadow: 5px 8px 20px black;
  width: 60vw;
}

.technologies {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.tech-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
  padding-left: 2vw;
  padding-right: 2vw;
  border: 1px solid white;
}

.frontend {
  display: flex;
}

.backend {
  display: flex;
}

.tech-images div {
  padding: 2vh 3vw 1vw 1vh;
}

.tech-images img {
  padding-top: 2vh;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 10vh;
}

.tech-images h4 {
  color: white;
  text-align: center;
  padding-top: 1vh;
}

.technologies h3 {
  color: white;
  font-size: 35px;
  text-align: center;

}

.homepage-bio {
  color: white;
  padding-top: 4vh;
  padding-bottom: 9vh;
}

.homepage-bio h3 {
  font-size: 35px;
  text-align: center;
}

.homepage-bio p {
  font-size: 23px;
  padding-left: 10vw;
  padding-right: 10vw;
  color: white; 
}

/* /////////////////////////////////////////// Projects ////////////////////////////////////////// */

.projects-page {
  background-color: #282c34;
}

.top-parallax {
   /* The image used */
  background-image: url('https://i.pinimg.com/originals/36/aa/7b/36aa7bcfc5899bb12952666806b152fe.jpg');

   /* Set a specific height */
  min-height: 50px; 

   /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax {
  /* The image used */
  background-image: url('https://i.pinimg.com/originals/36/aa/7b/36aa7bcfc5899bb12952666806b152fe.jpg');

  /* Set a specific height */
  min-height: 200px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projects-title h1 {
  padding-top: 2vh;
  color: white;
  text-align: center;
  font-size: 40px;
  font-family: 'Crimson Text', serif;
}

.top-cards, .bottom-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; 
  padding-bottom: 5vh;
}

.middle-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; 
  padding-bottom: 5vh;
}

.card-one, .card-two, .card-three, .card-four, .card-five, .card-six{
  padding: 2%;
  width: 40vw; 
}

@media only screen and (max-width: 800px) {
  .card-one, .card-two, .card-three, .card-four, .card-five, .card-six{
    width: 80vw;
  }
  .capstone-video iframe{
    min-width: 65vw;
  }
  .projects-title h1 {
    font-size: 9vw;
  }
}

#card-one, #card-two, #card-three, #card-four, #card-five, #card-six {
  background: rgb(249, 249, 249, 0.3);
  color: white;
  box-shadow: 5px 10px 18px black;
}

.projects-page a:hover {
  color: white;
  text-decoration: none;
}

#screenshot {
  padding: 3%;
}

#dropdown-text{
  color: black;
}

.capstone-video {
  margin-top: 1vh;
}

.video-card {
  margin-top: 2vh;
}

.capstone-video iframe {
  height: 50vh;
  width: 31vw;
}

.launch-app-button {
  box-shadow: 5px 8px 20px black;
}

.capstone-video-button {
  box-shadow: 5px 8px 20px black;
}

.project-title {
  font-size: 25px
}


/* /////////////////////////////////////////// Contact ////////////////////////////////////////// */

.contact {
  background-color: #282c34;
  color: white;
  height: 95vh;
}

.contact h1 {
  padding-top: 3vh;
  color: white;
  text-align: center;
  font-size: 40px;
  font-family: 'Crimson Text', serif;
  padding-bottom: 2vh;
}

.contact-main {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contact-info a {
  color: lightskyblue;
  text-decoration: none;
  font-size: 30px;
}

.contact-info a:hover {
  color: orange;
  text-decoration: none;
}

.contact-main h3 {
  padding-bottom: 3%;
}

.contact-image {
  box-shadow: 5px 10px 18px black;
}

@media screen and (max-width: 800px) {
  .contact {
    min-height: 93vh;
  }

  .contact h1 {
    font-size: 9vw;
  } 

  .contact-info h3 {
    font-size: 6vw;
  }

  .contact-info a {
    font-size: 6vw;
  }

  .contact-main {
    margin: 4%;
  }
  .contact-image img {
    height: 30vh;
    width: 50vw;
  }
}

/* /////////////////////////////////////////// Footer ////////////////////////////////////////// */

.footer {
  background-color: #282c34;
  color: white;
  position: absolute;
  bottom: 0%;
  width: 100vw;
  border: 1px solid black;
}

